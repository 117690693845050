<template>

    <a :href="route('search.page')+'/'+website.search.canonical.prev"
       @click.prevent="previous()" v-if="website.search.page_previous!==undefined &&  website.search.page_previous!==null"
            class="btn-default-gray">
        <span class="whitespace-nowrap text-xs mr-1">
            <CustomSvg :name="'chevron-left'" color="red" class=" text-blue-100 mr-2 dark:text-white"  :customClass="'fill-gray-500 w-3  '"></CustomSvg>
            <span class="hidden md:inline"> Vorige</span>
        </span>
    </a>

    <a  :href="route('search.page')+'/'+website.search.canonical.next"
        @click.prevent="next()" v-if="website.search.page_next!==undefined && website.search.page_next!==null"
            class="btn-default-gray whitespace-nowrap">
        <span class="whitespace-nowrap text-xs ml-0.5 ">
            <span class="hidden md:inline">Volgende</span>
            <CustomSvg :name="'chevron-right'" color="red" class="text-gray-600 mr-1 dark:text-white  "
                                :customClass="'fill-gray-500 w-3  !float-right ml-2 '"></CustomSvg>
        </span>
    </a>
</template>


<script>
import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';
import JetNavLink from '@/Jetstream/NavLink.vue'
export default {

    components: {
        CustomSvg,
        JetNavLink
    },
    props: {
        page: Object,
        website: Object
    },

    data() {
        return {

        }
    },
     methods: {
    //
        previous() {
            if (this.website.search.page_previous >= 0) {
                this.website.search.page = this.website.search.page_previous;
                this.onChange();
                let element = document.querySelector("#title");
                element.scrollIntoView({ behavior: 'smooth', block: 'start'});
            }
        },
        next() {
            this.website.search.page = this.website.search.page_next;
            this.onChange();
            let element = document.querySelector("#title");
            element.scrollIntoView({ behavior: 'smooth', block: 'start'});
        },
        onChange (event) {
            this.$emit('website_search', this.website.search);
        },
     },
     computed: {}
}
</script>

